import React from 'react';
import './css/NavbarProfile.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Auxiliar/images/LogoDS.svg';

function NavProfile() {
    return (
        <div className=''>
            <Navbar expand="lg" className="navbar-dark" >
                <Container>
                    <Navbar.Brand href="https://devspace.com.mx/"><img
                        src={logo}
                        className='logoNav'
                        alt='Logo DevSpace'
                    />
                    </Navbar.Brand>
                   
                </Container>
            </Navbar>
        </div>
    );
}

export default NavProfile;