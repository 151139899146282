import './css/Technologies.css'
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

//ICONOS
import BootstrapIcon from '../Auxiliar/images/Icons/BootstrapIcon.png';
import ReactIcon from '../Auxiliar/images/Icons/ReactIcon.png';
import AngularIcon from '../Auxiliar/images/Icons/AngularIcon.png';
import NetIcon from '../Auxiliar/images/Icons/NetIcon.png';
import NodejsIcon from '../Auxiliar/images/Icons/NodeJSIcon.png';
import CssIcon from '../Auxiliar/images/Icons/cssIcon.png';
import HtmlIcon from '../Auxiliar/images/Icons/htmlIcon.png';
import JavascriptIcon from '../Auxiliar/images/Icons/JavascriptIcon.png';
import PhpIcon from '../Auxiliar/images/Icons/phpIcon.png';
import JavaIcon from '../Auxiliar/images/Icons/javaIcon.png';
import FirebaseIcon from '../Auxiliar/images/Icons/firebaseIcon.png';
import SQLServerIcon from '../Auxiliar/images/Icons/sqlServerIcon.png';
import MysqlIcon from '../Auxiliar/images/Icons/mysqlIcon.png';
import WordpressIcon from '../Auxiliar/images/Icons/wordpressIcon.png';
import IllustratorIcon from '../Auxiliar/images/Icons/illustratorIcon.png';
import PhotoshopIcon from '../Auxiliar/images/Icons/photoshopIcon.png';
import SplineIcon from '../Auxiliar/images/Icons/splineIcon.png';
import FigmaIcon from '../Auxiliar/images/Icons/figmaIcon.png';

function Technologies() {
    return (
        <>
            <div className='mb-5' id='technologies'>
                <Container >
                    <div className='d-flex justify-content-center'>
                        <h2 className='aboutTitle mt-3 mb-4'>Tecnologías que usamos</h2>
                    </div>
                    <Row className='techContainer justify-content-center mb-5'>
                        <Carousel controls={false}>
                            <Carousel.Item interval={2000} className='slider-item'>
                                <div className='techCard py-4 px-4 mb-5 me-3 ms-3' >
                                    <h3 className='text-white titutloTecnologias'><strong>Desarrollo Web</strong></h3>
                                    <p className='lightGrayText'>En DevSpace, nos especializamos en el desarrollo de sitios web modernos y funcionales que cumplan con las necesidades específicas y expectativas de nuestros clientes.</p>
                                    <Row>
                                        <Col lg={3} md={3} sm={12} /*lg={3} md={3} sm={12}*/ className='mb-3'>
                                            <p className='lightGrayText'>Frontend</p>
                                            <div className='d-flex'>
                                                <img src={BootstrapIcon} className='techIcon'></img>
                                                <img src={ReactIcon} className='techIcon'></img>
                                                <img src={AngularIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Backend</p>
                                            <div className='d-flex'>
                                                <img src={NetIcon} className='techIcon'></img>
                                                <img src={NodejsIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Lenguajes</p>
                                            <div className='d-flex'>
                                                <img src={HtmlIcon} className='techIcon'></img>
                                                <img src={CssIcon} className='techIcon'></img>
                                                <img src={JavascriptIcon} className='techIcon'></img>
                                                <img src={PhpIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>CMS</p>
                                            <div className='d-flex'>
                                                <img src={WordpressIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={2000} className='slider-item'>
                                <div className='techCard py-4 px-4 mb-5 me-3 ms-3' >
                                    <h3 className='text-white titutloTecnologias'><strong>Aplicaciones móviles</strong></h3>
                                    <p className='lightGrayText'>Utilizamos tecnologías como React Native para desarrollar aplicaciones móviles para iOS y Android con una única base de código para acelerar el proceso de desarrollo.</p>
                                    <Row>
                                        <Col lg={3} md={3} sm={12} /*lg={3} md={3} sm={12}*/ className='mb-3'>
                                            <p className='lightGrayText'>Frontend</p>
                                            <div className='d-flex'>
                                                <img src={ReactIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Backend</p>
                                            <div className='d-flex'>
                                                <img src={FirebaseIcon} className='techIcon'></img>
                                                <img src={NodejsIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Lenguajes</p>
                                            <div className='d-flex'>
                                                <img src={JavascriptIcon} className='techIcon'></img>
                                                <img src={JavaIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Bases de datos</p>
                                            <div className='d-flex'>
                                                <img src={FirebaseIcon} className='techIcon'></img>
                                                <img src={MysqlIcon} className='techIcon'></img>
                                                <img src={SQLServerIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={2000} className='slider-item'>
                                <div className='techCard py-4 px-4 mb-5 me-3 ms-3' >
                                    <h3 className='text-white titutloTecnologias'><strong>Herramientas de Diseño</strong></h3>
                                    <p className='lightGrayText'>Entendemos que un diseño atractivo y funcional es crucial para el éxito de cualquier producto, Por lo que utilizamos herramientas de vanguardia para nuestros diseños.</p>
                                    <Row>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Diseño Gráfico</p>
                                            <div className='d-flex'>
                                                <img src={IllustratorIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Edición</p>
                                            <div className='d-flex'>
                                                <img src={PhotoshopIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col slg={3} md={3} m={12} className='mb-3'>
                                            <p className='lightGrayText'>Prototipado UX/UI</p>
                                            <div className='d-flex'>
                                                <img src={FigmaIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12} className='mb-3'>
                                            <p className='lightGrayText'>Diseño 3D</p>
                                            <div className='d-flex'>
                                                <img src={SplineIcon} className='techIcon'></img>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </Row>
                </Container>
            </div>
        </>

    );
}

export default Technologies;