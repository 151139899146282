import React from 'react';
import styles from './build/css/meeti.module.css';
//IMAGENES
import logo from './img/logo.svg';
import header from './img/header.jpg';
import icono1 from './img/icono_1.svg';
import icono2 from './img/icono_2.svg';
import icono3 from './img/icono_3.svg';
import evento1 from './img/evento_1.jpg';
import evento2 from './img/evento_2.jpg';
import evento3 from './img/evento_3.jpg';
import evento4 from './img/evento_4.jpg';
import tiendaAndroid from './img/tienda-android.svg';
import tiendaApple from './img/tienda-apple.svg';
import app1 from './img/app_1.png';
import app2 from './img/app_2.png';
function Meeti() {
    return (
        <>
            <div className={styles.meetiContainer}>
            <header className={`${styles["container-xl"]} ${styles.header}`}>
                    <div className={`${styles.row} ${styles["align-items-center"]}`}>
                        <div className={`${styles["col-md-6"]} ${styles["mb-5"]} ${styles["mb-md-0"]}`}>
                            <img className={styles.logo} src={logo} alt="logo meeti"></img>
                            <h1 className={`${styles["display-2"]}`}> Unete, la vida esta en un Meeti</h1>
                            <p className="fs-3">Unete a mas de 300mil organizaciones que estan compartiendo su pasion y construyendo una
                                comunidad</p>
                            <a className={`${styles.btn} ${styles["btn-danger"]}`}>Inicia un grupo</a>
                        </div>
                        <div className={`${styles["col-md-6"]}`}>
                            <img className={`${styles["img-fluid"]}`} src={header}></img>
                        </div>
                    </div>

                </header>

                <section className={`${styles["container-xl"]}`}>
                    <h2 className={`${styles["fs-1"]} ${styles["text-center"]}`}>Como funciona Meeti</h2>
                    <p className={`${styles["text-center"]} ${styles["w-50"]} ${styles["mx-auto"]}`}>Conoce a personas nuevas que compartan tus intereses a traves de eventos en
                        linea y en persona. Crear una cuenta es gratis.</p>
                    <div className={`${styles["mt-5"]} ${styles["row"]}`}>
                        <div className={`${styles["col-md-4"]}`}>
                            <img src={icono1} className={`${styles["img-fluid"]}`} alt="imagen icono"></img>
                            <h3 className={`${styles["text-secondary"]} ${styles["text-center"]} ${styles["fs-4"]}`}>Unete a un grupo</h3>
                            <p className={`${styles["text-center"]}`}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel adipisci architecto
                                ea debitis doloremque ipsum eaque velit cumque, natus minus in excepturi quos necessitatibus
                                accusantium.</p>
                        </div>
                        <div className={`${styles["col-md-4"]}`}>
                            <img src={icono2} className={`${styles["img-fluid"]}`} alt="imagen icono"></img>
                            <h3 className={`${styles["text-secondary"]} ${styles["text-center"]} ${styles["fs-4"]}`}>Administra asistentes</h3>
                            <p className={`${styles["text-center"]}`}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel adipisci architecto
                                ea debitis doloremque ipsum eaque velit cumque, natus minus in excepturi quos necessitatibus
                                accusantium.</p>
                        </div>
                        <div className={`${styles["col-md-4"]}`}>
                            <img src={icono3} className={`${styles["img-fluid"]}`} alt="imagen icono"></img>
                            <h3 className={`${styles["text-secondary"]} ${styles["text-center"]} ${styles["fs-4"]}`}>Crece tu comunidad</h3>
                            <p className={`${styles["text-center"]}`}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel adipisci architecto
                                ea debitis doloremque ipsum eaque velit cumque, natus minus in excepturi quos necessitatibus
                                accusantium.</p>
                        </div>
                    </div>
                    <div className={`${styles["d-flex"]} ${styles["justify-content-center"]} ${styles["my-5"]}`}>
                        <a href="" className={`${styles.btn} ${styles["btn-secondary"]} ${styles["text-white"]}`}>Unirse a Meeti</a>
                    </div>
                </section>


                <main className={`${styles["container-xl"]} ${styles["eventos"]} ${styles["mt-5"]}`}>
                    <div className={`${styles["d-flex"]} ${styles["justify-content-between"]}`}>
                        <h2>Proximos eventos</h2>
                        <a href="#" className={`${styles["text-secondary"]} ${styles["text-decoration-none"]}`}>Explora mas eventos</a>
                    </div>

                    <div className={`${styles["row"]} ${styles["mt-4"]}`}>
                        <div className={`${styles["col-md-6"]} ${styles["col-lg-3"]}`}>
                            <div className={`${styles["card"]}`}>
                                <img src={evento1} alt="imagen evento" className={`${styles["card-img-top"]}`}></img>
                                <div className={`${styles["card-body"]}`}>
                                    <p className={`${styles["card-text"]} ${styles["fecha"]} ${styles["fw-bold"]}`}>10 de Diciembre 20:00 horas</p>
                                    <h3 className={`${styles["card-title"]} ${styles["fs-5"]}`}>Diseño de apps con Swift</h3>
                                    <p className={`${styles["card-text"]} ${styles["descripcion"]}`}>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                        Nostrum officia fugiat eius</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles["col-md-6"]} ${styles["col-lg-3"]}`}>
                            <div className={`${styles["card"]}`}>
                                <img src={evento2} alt="imagen evento" className={`${styles["card-img-top"]}`}></img>
                                <div className={`${styles["card-body"]}`}>
                                    <p className={`${styles["card-text"]} ${styles["fecha"]} ${styles["fw-bold"]}`}>10 de Diciembre 20:00 horas</p>
                                    <h3 className={`${styles["card-title"]} ${styles["fs-5"]}`}>Marketing en Twitter</h3>
                                    <p className={`${styles["card-text"]} ${styles["descripcion"]}`}>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                        Nostrum officia fugiat eius</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles["col-md-6"]} ${styles["col-lg-3"]}`}>
                            <div className={`${styles["card"]}`}>
                                <img src={evento3} alt="imagen evento" className={`${styles["card-img-top"]}`}></img>
                                <div className={`${styles["card-body"]}`}>
                                    <p className={`${styles["card-text"]} ${styles["fecha"]} ${styles["fw-bold"]}`}>10 de Diciembre 20:00 horas</p>
                                    <h3 className={`${styles["card-title"]} ${styles["fs-5"]}`}>Ventas por Internet</h3>
                                    <p className={`${styles["card-text"]} ${styles["descripcion"]}`}>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                        Nostrum officia fugiat eius</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles["col-md-6"]} ${styles["col-lg-3"]}`}>
                            <div className={`${styles["card"]}`}>
                                <img src={evento4} alt="imagen evento" className={`${styles["card-img-top"]}`}></img>
                                <div className={`${styles["card-body"]}`}>
                                    <p className={`${styles["card-text"]} ${styles["fecha"]} ${styles["fw-bold"]}`}>10 de Diciembre 20:00 horas</p>
                                    <h3 className={`${styles["card-title"]} ${styles["fs-5"]}`}>Todo sobre Bitcoin</h3>
                                    <p className={`${styles["card-text"]} ${styles["descripcion"]}`}>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                        Nostrum officia fugiat eius</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <section className={`${styles["container-xl"]} ${styles["tiendas"]} ${styles["mt-5"]}`}>
                    <div className={`${styles["row"]}`}>
                        <div className={`${styles["col-md-4"]}`}>
                            <img className={`${styles["img-fluid"]}`} src={app1} alt="imagen app"></img>
                        </div>

                        <div className={`${styles["col-md-4"]}`}>
                            <img className={`${styles["logo"]} ${styles["mt-5"]} ${styles["d-block"]} ${styles["mx-auto"]}`} src={logo} alt="logo app"></img>
                            <p className={`${styles["text-center"]} ${styles["fw-bold"]} ${styles["mt-5"]} ${styles["fs-4"]}`}>Mantente Conectado. Descarga la aplicacion</p>
                            <div className={`${styles["d-flex"]} ${styles["gap-4"]} ${styles["mt-5"]} ${styles["fs-4"]}`}>
                                <div className={`${styles["col"]}`}>
                                    <a href="#">
                                        <img className={`${styles["img-fluid"]}`} src={tiendaApple}></img>
                                    </a>
                                </div>
                                <div className={`${styles["col"]}`}>
                                    <a href="#">
                                        <img className={`${styles["img-fluid"]}`} src={tiendaAndroid}></img>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles["col-md-4"]}`}>
                            <img className={`${styles["img-fluid"]}`} src={app2} alt="imagen app"></img>
                        </div>
                    </div>
                </section>

                <footer className={`${styles["footer"]} ${styles["bg-dark"]} ${styles["text-white"]} ${styles["mt-5"]} ${styles["pt-4"]} ${styles["pb-2"]}`}>
                    <div className={`${styles["container"]}`}>
                        <div className={`${styles["d-flex"]} ${styles["align-items-center"]} ${styles["border-bottom"]} ${styles["pb-3"]}`}>
                            <p className={`${styles["m-0"]} ${styles["me-3"]}`}>Crea tu propio grupo de Meeti</p>
                            <a href="#" className={`${styles["btn"]} ${styles["btn-dark"]} ${styles["border"]}`}>Primeros Pasos</a>
                        </div>
                        <div className={`${styles["row"]} ${styles["mt-3"]}`}>
                            <div className={`${styles["col-md-4"]}`}>
                                <p className={`${styles["fs-5"]}`}>Tu cuenta</p>
                                <nav className={`${styles["nav"]} ${styles["flex-column"]}`}>
                                    <a className={`${styles["nav-link"]}`} href="#">Registrarse</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Iniciar Sesion</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Ayuda</a>
                                </nav>
                            </div>
                            <div className={`${styles["col-md-4"]}`}>
                                <p className={`${styles["fs-5"]}`}>Descubrir</p>
                                <nav className={`${styles["nav"]} ${styles["flex-column"]}`}>
                                    <a className={`${styles["nav-link"]}`} href="#">Grupos</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Calendario</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Temas</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Ciudades</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Eventos en Linea</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Guias Locales</a>
                                </nav>
                            </div>
                            <div className={`${styles["col-md-4"]}`}>
                                <p className={`${styles["fs-5"]}`}>Meeti</p>
                                <nav className={`${styles["nav"]} ${styles["flex-column"]}`}>
                                    <a className={`${styles["nav-link"]}`} href="#">Acerca de</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Blog</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Meeti PRO</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Empleo</a>
                                    <a className={`${styles["nav-link"]}`} href="#">Aplicaciones</a>
                                </nav>
                            </div>
                        </div>

                        <div className={`${styles["d-flex"]} ${styles["flex-column"]} ${styles["flex-md-row"]} ${styles["align-items-center"]}`}>
                            <p className={`${styles["m-0"]} ${styles["me-2"]}`}>Meeti</p>
                            <nav className={`${styles["nav"]} ${styles["flex-colum"]} ${styles["flex-md-row"]}`}>
                                <a className={`${styles["nav-link"]} ${styles["me-md-2"]}`} href="#">Condiciones del servicio</a>
                                <a className={`${styles["nav-link"]} ${styles["me-md-2"]}`} href="#">Politica de privacidad</a>
                                <a className={`${styles["nav-link"]} ${styles["me-md-2"]}`} href="#">Meeti PRO</a>
                                <a className={`${styles["nav-link"]} ${styles["me-md-2"]}`} href="#">Empleo</a>
                                <a className={`${styles["nav-link"]} ${styles["me-md-2"]}`} href="#">Aplicaciones</a>
                            </nav>
                        </div>
                    </div>
                </footer>
            </div>
        </>

    );
}

export default Meeti;