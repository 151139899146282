import React from 'react';
import './css/HomeLayout.css';
import Navbar from './Navbar';
import Carrusel from './Carrusel';
import AboutUs from './AboutUs';
import Services from './Services';
import Projects from './Projects';
import Footer from './Footer';
import Location from './Location';
import Technologies from './Technologies';
import Benefits from './Benefits';

function HomeLayout() {
    return (
        <>
            <div className='bgImageLap'>
                <Navbar></Navbar>
                <Carrusel></Carrusel>
            </div>
            <AboutUs></AboutUs>
            <Services></Services>
            <Benefits></Benefits>
            <Technologies></Technologies>
            <Projects></Projects>
            <Location></Location>
            <Footer></Footer>   
            
        </>

    );
}

export default HomeLayout;