import './css/Profile.css'
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//IMAGES
import guittar from '../Auxiliar/images/guittar.png';
import lideres from '../Auxiliar/images/lideresdealtura.jpeg';
import meeti from '../Auxiliar/images/meeti.png';
import podcast from '../Auxiliar/images/podcast.png';
import profilePicture from '../Auxiliar/images/profilePicture.jpg';
import instagram from '../Auxiliar/images/RedInstagram.png';
import linkedin from '../Auxiliar/images/RedLinkedin.png';
import whatsapp from '../Auxiliar/images/RedWhatsapp.png';
import web from '../Auxiliar/images/RedWeb.png';


import BootstrapIcon from '../Auxiliar/images/Icons/BootstrapIcon.png';
import ReactIcon from '../Auxiliar/images/Icons/ReactIcon.png';
import AngularIcon from '../Auxiliar/images/Icons/AngularIcon.png';

import SQLServerIcon from '../Auxiliar/images/Icons/sqlServerIcon.png'
import FirebaseIcon from '../Auxiliar/images/Icons/firebaseIcon.png';
import FigmaIcon from '../Auxiliar/images/Icons/figmaIcon.png';
import JavaIcon from '../Auxiliar/images/Icons/javaIcon.png';
import CssIcon from '../Auxiliar/images/Icons/cssIcon.png';
import JavascriptIcon from '../Auxiliar/images/Icons/JavascriptIcon.png';
import HtmlIcon from '../Auxiliar/images/Icons/htmlIcon.png';
import PhpIcon from '../Auxiliar/images/Icons/phpIcon.png';


import { Link } from 'react-router-dom';
import NavProfile from './NavBarProfile';


function Profile() {
    return (
        <>
            <NavProfile></NavProfile>
            <div className='mt-3' id='perfil'>
                <Container>
                    <Row className='projectContainer justify-content-center ms-4 me-4 mb-5'>
                        <Col lg={4} md={6} className='profileCol'>
                            <div className='profileCard'>
                                <div className='portada d-flex justify-content-center pt-4'>
                                    <img src={profilePicture} alt='' className='card-img-top profilePicture' />
                                </div>
                                <Card.Body className='pCardBody'>
                                    <div className='mb-3'>
                                        <Card.Title className='profileName fs-3'>Luis Fernando Jasso Frausto</Card.Title>
                                        <Card.Text className='profileDesc d-flex'>
                                        <i class="bi bi-person-lines-fill profileIcon"></i>
                                            <p className='auxText'>Desarrollador Full Stack.</p>
                                        </Card.Text>
                                        <Card.Text className='profileDesc d-flex'>
                                        <i class="bi bi-envelope-fill profileIcon"></i>
                                            <p className='auxText'  >proyectos@devspace.com.mx</p>
                                        </Card.Text>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Row className=''>
                                            <Col xs={6} md={6} lg={6} className='mb-3'>
                                            <a href='https://wa.me/message/Z76INNTNEOROG1' target="_blank" className='text-decoration-none'>
                                                <div className='redContainer py-2'>
                                                    <div className='iconContainer'>
                                                        <img src={whatsapp} className='w-25'></img>
                                                    </div>
                                                    <div >
                                                        <p className='text-white text-center contactText'><strong>WhatsApp</strong></p>
                                                        <p className='text-white text-center contactText'>449-323-0592</p>
                                                    </div>
                                                </div>
                                            </a>
                                            </Col>
                                            <Col xs={6} md={6} lg={6} className='mb-3'>
                                            <a href='https://www.linkedin.com/in/luis-fernando-jasso-frausto-a8764a1b1' target="_blank" className='text-decoration-none'>
                                                <div className='redContainer py-2'>
                                                    <div className='iconContainer'>
                                                        <img src={linkedin} className='w-25'></img>
                                                    </div>
                                                    <div >
                                                        <p className='text-white text-center contactText'><strong>LinkedIn</strong></p>
                                                        <p className='text-white text-center contactText'>Luis Jasso</p>
                                                    </div>
                                                </div>
                                            </a>
                                            </Col>
                                            <Col xs={6} md={6} lg={6} className='mb-3'>
                                            <a href='https://www.instagram.com/luis_jasso22' target="_blank" className='text-decoration-none'>
                                                <div className='redContainer py-2'>
                                                    <div className='iconContainer'>
                                                        <img src={instagram} className='w-25'></img>
                                                    </div>
                                                    <div >
                                                        <p className='text-white text-center contactText'><strong>Instagram</strong></p>
                                                        <p className='text-white text-center contactText'>@luis_jasso22</p>
                                                    </div>
                                                </div>
                                            </a>
                                            </Col>
                                            <Col xs={6} md={6} lg={6} className='mb-3'>
                                            <a href='https://devspace.com.mx/' target="_blank" className='text-decoration-none'>
                                                <div className='redContainer py-2'>
                                                    <div className='iconContainer'>
                                                        <img src={web} className='w-25'></img>
                                                    </div>
                                                    <div >
                                                        <p className='text-white text-center contactText'><strong>Sitio web</strong></p>
                                                        <p className='text-white text-center contactText'>devspace.com.mx</p>
                                                    </div>
                                                </div>
                                            </a>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Card.Text className='projectDesc mt-2 fs-5'>
                                        Tecnologías:
                                    </Card.Text>
                                    <Row className='techContainer mb-3'>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={BootstrapIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={ReactIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={AngularIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={HtmlIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={CssIcon} className='techIconProfile'></img></Col>
                                    </Row>
                                    <Row className='techContainer mb-3'>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={JavascriptIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={FirebaseIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={FigmaIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={SQLServerIcon} className='techIconProfile'></img></Col>
                                        <Col lg={2} md={2} xs={2} className='techCol'><img src={JavaIcon} className='techIconProfile'></img></Col>
                                    </Row>

                                </Card.Body>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>

    );
}

export default Profile;