import './css/AboutUs.css'
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aboutImage from '../Auxiliar/images/ImagenImac.svg';
import aboutImage1 from '../Auxiliar/images/ImagenImac.png';

function AboutUs() {
    return (
        <>
            <Container className='mb-5' id='quienesSomos'>
                    <Row>
                        <Col lg={6} className='leftContainer'>
                            <div className='paragraphContainer'>
                                <div className='d-flex titleCont'>
                                    <h2 className='aboutTitle'>¿Quienes somos?</h2>
                                </div>
                                <p className='aboutParagraph'>Somos una empresa joven con ideas frescas ubicada en Aguascalientes donde nos dedicamos a impulsar el éxito de tu negocio en el mundo digital, nos comprometemos con ofrecer soluciones de vanguardia en el desarrollo de landing pages que potencien la presencia en línea de empresas y emprendedores.¡Confía en DevSpace para impulsar tu presencia en línea y alcanzar tus objetivos comerciales!</p>
                            </div>
                        </Col>
                        <Col lg={6} className='rightContainer'>
                            <img src={aboutImage1} alt="imagen ejemplo sitio web" className='aboutImg' />
                        </Col>
                    </Row>
            </Container>
        </>

    );
}

export default AboutUs;