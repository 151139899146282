import './css/Benefits.css'
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import benefitsImg from '../Auxiliar/images/BenefitsImage.png';
import podium from '../Auxiliar/images/podiumIcon.svg';
import leads from '../Auxiliar/images/LeadIcon.svg';
import growth from '../Auxiliar/images/growthIcon.svg';
import money from '../Auxiliar/images/moneyIcon.svg';

function Benefits() {
    return (
        <>
            <div className='benefitsContainer pt-4 pb-4'>
                <Container className='mb-5' id='quienesSomos'>
                    <Row className=''>
                        <Col xs={12} md={6} lg={6} className='benefContainer'>
                            <div className='mb-5 mt-5'><h4 className='beneficiosTitle'>Beneficios de tener presencia en la web</h4></div>
                            <Row>
                                <Col xs={6} md={6} lg={6}>
                                    <img src={leads} className='benefitIcon mb-3'></img>
                                    <p className='text-white'><strong>Generación de nuevos leads</strong></p>
                                    <p className='textBenef'>Las landing pages son herramientas poderosas para captar la información de contacto de clientes potenciales interesados en tus productos o servicios.</p>
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <img src={podium} className='benefitIcon mb-3'></img>
                                    <p className='text-white'><strong>Fortalecimiento de la marca</strong></p>
                                    <p className='textBenef'>Una landing page profesional y bien diseñada contribuye a reforzar la imagen de tu marca y a generar confianza en tus clientes potenciales.</p>
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <img src={money} className='benefitIcon mb-3'></img>
                                    <p className='text-white'><strong>Aumento de conversiones</strong></p>
                                    <p className='textBenef'>Con una página optimizada para la conversión, puedes captar la atención de clientes y resaltar las ventajas únicas de tus productos o servicios.</p>
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <img src={growth} className='benefitIcon mb-3'></img>
                                    <p className='text-white'><strong>Impulsa el crecimiento de tu empresa</strong></p>
                                    <p className='textBenef'>Una landing page es una herramienta para acelerar el cumplimiento de tus metas, aumentando las ventas y fortaleciendo tu presencia en la web.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='benefitImgCont'>
                            <img src={benefitsImg} className='w-75'></img>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>

    );
}

export default Benefits;