import Carousel from 'react-bootstrap/Carousel';
import './css/Carrusel.css';
import img1 from '../Auxiliar/images/ImgProdPerspective.png';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { Fade } from "react-awesome-reveal";

function Slider() {
    return (
        <div className='mb-5'>
            <Carousel fade controls={false}>
                <Carousel.Item className='itemContainer'>
                    <Carousel.Caption className='d-flex flex-column justify-content-start h-100 text-start caption'>
                        <Fade>
                            <Row>
                                <Col lg={6} md={6} className='order-md-1 order-sm-2 order-2'>
                                    <div className='lSideCont'>
                                        <img className="d-block bannerImg" src={img1} alt="First slide" />
                                    </div>
                                </Col>
                                <Col lg={6} md={6} className='headerTitle order-md-2 order-sm-1 order-1'>
                                    <div className='rSideCont'>
                                        <h1 className='text-start mb-4 bannerTitle'>Digitaliza tu negocio con DevSpace</h1>
                                        <Button variant="primary" href="https://wa.me/5214493230592" className='rounded-pill btnCotizar mb-3'>¡Cotiza ahora!</Button>
                                        <br></br>
                                        <Button variant="outline-light" href='#servicios' className='rounded-pill btnServicios d-none d-md-inline'>Conoce nuestros servicios</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Fade>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div >
    );
}

export default Slider;

/**<Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={bg}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={bg}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item> */

                