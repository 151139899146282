import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map from '../Auxiliar/images/map3d.png';
import './css/Location.css';
function AboutUs() {
    return (
        <>
            <Container className='mb-5 py-3' id='ubicacion'>
                <Row className='rowsContainer'>
                    <Col lg={6} className='leftContainerL'>
                        <img src={map} alt="about us" className='locationImg' />
                    </Col>
                    <Col lg={6} className='rightContainerL order-xs-first'>
                        <div className='paragraphContainerLocation'>
                            <div className='d-flex titleContLocation'>
                                <h2 className='aboutTitle'>Ubicación</h2>
                            </div>
                            <p className='aboutParagraphLocation'>Estamos ubicados en el estado de Aguascalientes, sin embargo la tecnología nos permite trabajar proyectos con clientes que se encuentren en cualquier parte de la república Mexicana, sin importar donde te encuentres ¡podemos apoyarte!</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>

    );
}

export default AboutUs;