import './css/Services.css'
import React from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
//IMAGES
import logo from '../Auxiliar/images/iconoLogos.svg';
import develop from '../Auxiliar/images/iconoDesarrollo.svg';
import camera from '../Auxiliar/images/iconoCamara.svg';
import design from '../Auxiliar/images/iconoDiseño.svg';
import apps from '../Auxiliar/images/iconoApps.svg';
import systems from '../Auxiliar/images/iconoSistemas.svg';
import imageSerWeb from '../Auxiliar/images/imageWebService.png';
import imageSerFoto from '../Auxiliar/images/imageFotoService.png';
import cards from '../Auxiliar/images/iconoCard.svg';
import soporte from '../Auxiliar/images/iconoSoporte.svg';
//MODALS
import Modal from 'react-bootstrap/Modal';

function Services() {
    //VARIABLES DE MODALS
    const [showWeb, setShowWeb] = useState(false);
    const handleCloseWeb = () => setShowWeb(false);
    const handleShowWeb = () => setShowWeb(true);

    const [showApps, setShowApps] = useState(false);
    const handleCloseApps = () => setShowApps(false);
    const handleShowApps = () => setShowApps(true);


    const [showSistemas, setShowSistemas] = useState(false);
    const handleCloseSistemas = () => setShowSistemas(false);
    const handleShowSistemas = () => setShowSistemas(true);


    const [showFoto, setShowFoto] = useState(false);
    const handleCloseFoto = () => setShowFoto(false);
    const handleShowFoto = () => setShowFoto(true);


    const [showDiseño, setShowDiseño] = useState(false);
    const handleCloseDiseño = () => setShowDiseño(false);
    const handleShowDiseño = () => setShowDiseño(true);


    const [showLogos, setShowLogos] = useState(false);
    const handleCloseLogos = () => setShowLogos(false);
    const handleShowLogos = () => setShowLogos(true);

    return (
        <>
            <div id='servicios' className='servicesBg'>

                <div className='blueBg' >
                    <Container>
                        <div className='d-flex justify-content-center'>
                            <h2 className='servTitle mt-3 mb-4'>Servicios</h2>
                        </div>
                        <Row className='servicesContainer pb-4 justify-content-center'>

                            <Col lg={3} md={4} xs={6} className='services'>
                                <div className='serviceCard'>
                                    <Card.Body className='cardBody'>
                                        <div>
                                            <img src={develop} alt='servicio fotografía' className='cardIcon mb-2' />
                                            <Card.Title className='serviceTitle mb-2'>Desarrollo web</Card.Title>
                                            <Card.Text className='cardText mb-3'>
                                                Nuestro servicio de desarrollo web está diseñado para ayudarte a mostrar toda la esencia de tu empresa en un entorno web
                                                que refleje tu marca y conecte con tu audiencia.
                                            </Card.Text>
                                        </div>
                                        <div>
                                            <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowWeb}*/>Cotizar
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                </svg>
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col lg={3} md={4} xs={6} className='services'>
                                <div className='serviceCard'>
                                    <Card.Body className='cardBody'>
                                        <div>
                                            <img src={apps} alt='servicio fotografía' className='cardIcon mb-2' />
                                            <Card.Title className='serviceTitle mb-2'>Desarrollo de Apps</Card.Title>
                                            <Card.Text className='cardText mb-3'>
                                                En DevSpace, transformamos tus ideas en aplicaciones móviles innovadoras y funcionales. Nuestro equipo se dedica a crear
                                                aplicaciones intuitivas y de alto rendimiento para iOS y Android.
                                            </Card.Text>
                                        </div>
                                        <div>
                                            <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowApps}*/>Cotizar
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                </svg>
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col lg={3} md={4} xs={6} className='services'>
                                <div className='serviceCard'>
                                    <Card.Body className='cardBody'>
                                        <div>
                                            <img src={systems} alt='servicio fotografía' className='cardIcon mb-2' />
                                            <Card.Title className='serviceTitle mb-2'>Desarrollo de Sistemas</Card.Title>
                                            <Card.Text className='cardText mb-3'>
                                                En DevSpace, ofrecemos soluciones de desarrollo de sistemas administrativos a medida que se adaptan a tus necesidades
                                                operativas para optimizar la gestión y eficiencia de tu negocio.
                                            </Card.Text>
                                        </div>
                                        <div>
                                            <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowSistemas}*/>Cotizar
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                </svg>
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col lg={3} md={4} xs={6} className='services'>
                            <div className='serviceCard'>
                                <Card.Body className='cardBody'>
                                    <div>
                                        <img src={cards} alt='servicio fotografía' className='cardIcon mb-2' />
                                        <Card.Title className='serviceTitle mb-2'>Tarjetas Contactless</Card.Title>
                                        <Card.Text className='cardText mb-3'>
                                            Olvídate del papel y destaca en cada encuentro con una forma moderna y eficiente de conectarte. En DevSpace, estamos llevando la forma de compartir tus datos al siguiente nivel con nuestras innovadoras tarjetas de presentación contactless.
                                        </Card.Text>
                                    </div>
                                    <div>
                                        <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowLogos}*/>Cotizar
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>

                        </Row>
                    </Container>
                </div>
                <Container className='servContainer'>
                    <Row className='justify-content-center servicesContainer'>
                        <Col lg={3} md={4} xs={6} className='services'>
                            <div className='serviceCard'>
                                <Card.Body className='cardBody'>
                                    <div>
                                        <img src={design} alt='servicio fotografía' className='cardIcon mb-2' />
                                        <Card.Title className='serviceTitle mb-2'>Diseño grafico</Card.Title>
                                        <Card.Text className='cardText mb-3'>
                                            Nuestro servicio de diseño gráfico ofrece soluciones creativas y personalizadas para todas tus necesidades de branding y
                                            marketing.
                                        </Card.Text>
                                    </div>
                                    <div>
                                        <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowDiseño}*/>Cotizar
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={6} className='services'>
                            <div className='serviceCard'>
                                <Card.Body className='cardBody'>
                                    <div>
                                        <img src={logo} alt='servicio fotografía' className='cardIcon mb-2' />
                                        <Card.Title className='serviceTitle mb-2'>Logos</Card.Title>
                                        <Card.Text className='cardText mb-3'>
                                            Entendemos que el logo es la piedra angular de la identidad de tu marca. Nuestro servicio de diseño de logos está enfocado a
                                            ayudarte a dar vida a tu visión y establecer una presencia distintiva en el mercado.
                                        </Card.Text>
                                    </div>
                                    <div>
                                        <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowLogos}*/>Cotizar
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={6} className='services'>
                            <div className='serviceCard'>
                                <Card.Body className='cardBody'>
                                    <div>
                                        <img src={soporte} alt='servicio fotografía' className='cardIcon mb-2' />
                                        <Card.Title className='serviceTitle mb-2'>Soporte en equipos de computo</Card.Title>
                                        <Card.Text className='cardText mb-3'>
                                        Nuestro servicio de soporte y mantenimiento ofrece soluciones rápidas y efectivas para prevenir y resolver cualquier problema técnico, desde actualizaciones de software hasta reparaciones de hardware.  
                                        </Card.Text>
                                    </div>
                                    <div>
                                        <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowLogos}*/>Cotizar
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                       
                        <Col lg={3} md={4} xs={6} className='services'>
                                <div className='serviceCard'>
                                    <Card.Body className='cardBody'>
                                        <div>
                                            <img src={camera} alt='servicio fotografía' className='cardIcon mb-2' />
                                            <Card.Title className='serviceTitle mb-2'>Fotografía</Card.Title>
                                            <Card.Text className='cardText mb-3'>
                                                Nuestro servicio de fotografía está diseñado para capturar la esencia de tu negocio. Nos especializamos en tomar fotografías
                                                profesionales de tus instalaciones, productos destacados y el equipo.
                                            </Card.Text>
                                        </div>
                                        <div>
                                            <Button as={Link} to="https://wa.me/5214493230592" variant="btn btnPurple rounded-pill" /*onClick={handleShowFoto}*/>Cotizar
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                </svg>
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </div>
                            </Col>
                    </Row>
                </Container>

            </div>

            <Modal show={showWeb} onHide={handleCloseWeb} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <div className='d-flex justify-content-center'>
                        <h3 className='modalTitle'>Desarrollo Web</h3>
                    </div>
                </Modal.Header>
                <hr className='text-white separator'></hr>
                <Row className='px-3 py-3'>
                    <Col lg={6} md={6} sm={12} className='order-lg-1 order-md-1 order-1'>
                        <div className='d-flex justify-content-center'>
                            <img src={imageSerWeb} className='serviceImage'></img>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='order-lg-2 order-md-2 order-2'>
                        <div className='py-2 px-2'>
                            <p className='text-white'>Nuestro servicio de desarrollo web está diseñado para ayudarte a mostrar toda la esencia de tu empresa en un entorno web que refleje tu marca y conecte con tu audiencia. Nos enorgullece ofrecer un enfoque personalizado y centrado en el cliente para cada proyecto de desarrollo web. Trabajamos estrechamente contigo para comprender tus objetivos y necesidades únicas, y luego creamos un sitio web que cumpla y supere tus expectativas.Confía en nosotros para crear un sitio web que no solo te ayude a destacar en un mercado saturado, sino que también te ayude a alcanzar tus objetivos comerciales.</p>
                            <Button variant="btn btnPurple rounded-pill">¡Contactanos!</Button>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <Modal show={showApps} onHide={handleCloseApps} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <div className='d-flex justify-content-center'>
                        <h3 className='modalTitle'>Desarrollo de Aplicaciones</h3>
                    </div>
                </Modal.Header>
                <hr className='text-white separator'></hr>
                <Row className='px-3 py-3'>
                    <Col lg={6} md={6} sm={12} className='order-lg-1 order-md-1 order-1'>
                        <div className='d-flex justify-content-center'>
                            <img src={imageSerFoto} className='serviceImage'></img>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='order-lg-2 order-md-2 order-2'>
                        <div className='py-2 px-2'>
                            <p className='text-white'>En DevSpace, transformamos tus ideas en aplicaciones móviles innovadoras y funcionales. Nuestro equipo de expertos en desarrollo móvil se dedica a crear aplicaciones intuitivas y de alto rendimiento para iOS y Android, garantizando una experiencia de usuario excepcional.</p>

                            <Button variant="btn btnPurple rounded-pill">¡Contactanos!</Button>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <Modal show={showSistemas} onHide={handleCloseSistemas} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <div className='d-flex justify-content-center'>
                        <h3 className='modalTitle'>Desarrollo de Sistemas Administrativos</h3>
                    </div>
                </Modal.Header>
                <hr className='text-white separator'></hr>
                <Row className='px-3 py-3'>
                    <Col lg={6} md={6} sm={12} className='order-lg-1 order-md-1 order-1'>
                        <div className='d-flex justify-content-center'>
                            <img src={imageSerFoto} className='serviceImage'></img>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='order-lg-2 order-md-2 order-2'>
                        <div className='py-2 px-2'>
                            <p className='text-white'>En DevSpace, ofrecemos soluciones de desarrollo de sistemas administrativos a medida para optimizar la gestión y eficiencia de tu negocio. Nuestro equipo se especializa en crear plataformas robustas e intuitivas que se adaptan a tus necesidades operativas y mejoran la toma de decisiones.</p>

                            <Button variant="btn btnPurple rounded-pill">¡Contactanos!</Button>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <Modal show={showFoto} onHide={handleCloseFoto} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <div className='d-flex justify-content-center'>
                        <h3 className='modalTitle'>Fotografía</h3>
                    </div>
                </Modal.Header>
                <hr className='text-white separator'></hr>
                <Row className='px-3 py-3'>
                    <Col lg={6} md={6} sm={12} className='order-lg-1 order-md-1 order-1'>
                        <div className='d-flex justify-content-center'>
                            <img src={imageSerFoto} className='serviceImage'></img>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='order-lg-2 order-md-2 order-2'>
                        <div className='py-2 px-2'>
                            <p className='text-white'>Nuestro servicio de fotografía en DevSpace está diseñado para capturar la esencia y la belleza de tu negocio. Nos especializamos en tomar fotografías profesionales de tus instalaciones, productos destacados y el equipo humano que impulsa tu empresa hacia el éxito.Ya sea que necesites imágenes nítidas y detalladas de tus productos, fotografías inspiradoras de tus instalaciones o retratos auténticos de tu equipo, estamos aquí para proporcionarte un contenido visual impresionante.</p>

                            <Button variant="btn btnPurple rounded-pill">¡Contactanos!</Button>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <Modal show={showDiseño} onHide={handleCloseDiseño} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <div className='d-flex justify-content-center'>
                        <h3 className='modalTitle'>Diseño Gráfico</h3>
                    </div>
                </Modal.Header>
                <hr className='text-white separator'></hr>
                <Row className='px-3 py-3'>
                    <Col lg={6} md={6} sm={12} className='order-lg-1 order-md-1 order-1'>
                        <div className='d-flex justify-content-center'>
                            <img src={imageSerFoto} className='serviceImage'></img>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='order-lg-2 order-md-2 order-2'>
                        <div className='py-2 px-2'>
                            <p className='text-white'>Nuestro servicio de diseño gráfico ofrece soluciones creativas y personalizadas para todas tus necesidades de branding y marketing. Desde el diseño de tarjetas de presentación que dejen una impresión duradera hasta el desarrollo de diseños impactantes para lonas publicitarias que atraigan la atención de tus clientes potenciales, ya sea que necesites una imagen fresca para tu negocio en línea o materiales impresos que destaquen tu marca en el mundo físico, podemos hacerlo realidad.</p>

                            <Button variant="btn btnPurple rounded-pill">¡Contactanos!</Button>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <Modal show={showLogos} onHide={handleCloseLogos} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <div className='d-flex justify-content-center'>
                        <h3 className='modalTitle'>Logos</h3>
                    </div>
                </Modal.Header>
                <hr className='text-white separator'></hr>
                <Row className='px-3 py-3'>
                    <Col lg={6} md={6} sm={12} className='order-lg-1 order-md-1 order-1'>
                        <div className='d-flex justify-content-center'>
                            <img src={imageSerFoto} className='serviceImage'></img>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='order-lg-2 order-md-2 order-2'>
                        <div className='py-2 px-2'>
                            <p className='text-white'>En DevSpace, entendemos que el logo es la piedra angular de la identidad de tu marca. Nuestro servicio de creación de logos está diseñado para ayudar a las empresas de reciente creación a dar vida a su visión y establecer una presencia distintiva en el mercado. Desde el diseño de conceptos iniciales hasta la entrega del logo final, trabajamos estrechamente contigo para garantizar que tu logo refleje la personalidad y los valores de tu marca.Ya sea que estés buscando un logo elegante y minimalista o un diseño audaz y llamativo, estamos aquí para hacerlo realidad.</p>

                            <Button variant="btn btnPurple rounded-pill">¡Contactanos!</Button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>

    );
}
/*
                        <Col lg={2} md={4} xs={6} className='services'>
                            <div className='serviceCard'>
                                <img src={asesoria} alt='servicio fotografía' className='cardIcon' />
                                <Card.Body className='cardBody'>
                                    <Card.Title className='serviceTitle'>Asesoría</Card.Title>
                                    <Card.Text className='cardText'>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </Card.Text>
                                    <a className='readMore'>Leer más
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#8048F5" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                        </svg>
                                    </a>
                                </Card.Body>
                            </div>
                        </Col>*/
export default Services;