import './css/Footer.css';
import whatsapp from '../Auxiliar/images/iconoWhatsapp.svg';
import mail from '../Auxiliar/images/iconoMail.svg';
import clock from '../Auxiliar/images/iconoReloj.svg';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';

function Footer() {
    return (
        <div className='footer' id='footer'>
            <Container>
                <div className='d-flex justify-content-center'>
                    <h2 className='aboutTitle mt-3 mb-4'>¡Comienza tu proyecto!</h2>
                </div>
                <Row className='justify-content-center'>
                    <Col lg={5}>
                        <p className='text-light subtitle'>Dejanos un mensaje</p>
                        <p className='text-gray secondaryText'>Contactanos y dejanos tus datos, nosotros te llamaremos a la brevedad</p>
                        <div className='dataContainer'>
                            <img src={whatsapp} alt='' className='footerIcon' />
                            <p className='text-gray data'><span className='text-light'>Whatsapp: </span>449-323-0592</p>
                        </div>
                        <div className='dataContainer'>
                            <img src={mail} alt='' className='footerIcon' />
                            <p className='text-gray data'><span className='text-light'>Correo: </span>proyectos@devspace.com</p>
                        </div>
                        <div className='dataContainer'>
                            <img src={clock} alt='' className='footerIcon' />
                            <p className='text-gray data'><span className='text-light'>Horario: </span>Lun - Vier 9:30 a 18:30 hrs.</p>
                        </div>
                    </Col>
                    <Col lg={5} className='btnContactFooter'>
                      
                    <Button variant="primary" href="https://wa.me/5214493230592" className='rounded-pill btnCotizar mb-3 fuenteFooterBtn'>¡Contactanos para cotizar tu proyecto!</Button>
                    </Col>
                </Row>
                <hr className='separator my-4'></hr>
                <p className='copyright'>2024 - DevSpace, Todos los derechos reservados</p>
            </Container>
        </div >
    );
}

export default Footer;

/*<div className='formContainer'>
<p className='text-light formTitle'>Contacto</p>
<form action="https://formsubmit.co/f91ed93c2b9c1614368431b710e6a3db" method="POST">
    <input type="text" className="form-control mb-3" id="nameInput" name='nombre' placeholder="Nombre*" />
    <Row className='mb-3'>
        <Col>
            <input type="email" className="form-control" id="mailInput" name='correo' placeholder="Correo*" />
        </Col>
        <Col>
            <input type="number" className="form-control" id="telInput" name='telefono'  placeholder="Teléfono*" />
        </Col>
    </Row>
    <textarea className="form-control mb-3" id="msgInput" rows="3" name='mensaje' placeholder="Duda o mensaje"></textarea>
    
    <input type="hidden" name='_captcha' value="false"/>
    <input type="hidden" name='_next' value="https://devspace.com.mx/"/>
    
    <div className='submitCnt'>
        <Button variant="primary" className='rounded-pill submitBtn' type='submit'>Enviar</Button>
    </div>
</form>
</div>*/