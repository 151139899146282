import React from 'react';
import './css/Navbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Auxiliar/images/LogoDS.svg';

function Menu() {
    return (
        <div className='navContainer'>
            <Navbar expand="lg" className="navbar-dark" >
                <Container>
                    <Navbar.Brand href="#home"><img
                        src={logo}
                        className='logoNav'
                        alt='Logo DevSpace'
                    />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="#home" className='navLink'>Inicio</Nav.Link>
                            <Nav.Link href="#quienesSomos" className='navLink'>¿Quienes somos?</Nav.Link>
                            <Nav.Link href="#servicios" className='navLink'>Servicios</Nav.Link>
                            <Nav.Link href="#proyectos" className='navLink'>Nuestro trabajo</Nav.Link>
                            <Nav.Link href="#footer" className='navLink'>Contacto</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Menu;