import './App.css';

import { Routes, Route } from "react-router-dom"
import { BrowserRouter } from "react-router-dom"
import HomeLayout from './Components/Home/HomeLayout';
import Meeti from './Components/Demos/Meeti/Meeti';
import PodcastApp from './Components/Demos/PodcastApp/PodcastApp';
import ScrollToTop from './Components/ScrollTop';
import Profile from './Components/Contact/Profile';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path='/' element={<HomeLayout/>} />
          <Route path='/meeti' element={<Meeti/>} />
          <Route path='/podcast' element={<PodcastApp/>} />
          <Route path='/podcast' element={<PodcastApp/>} />
          <Route path='/perfil-direccion' element={<Profile/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
