import './css/Projects.css'
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//IMAGES
import guittar from '../Auxiliar/images/guittar.png';
import lideres from '../Auxiliar/images/lideresdealtura.jpeg';
import meeti from '../Auxiliar/images/meeti.png';
import podcast from '../Auxiliar/images/podcast.png';
import { Link } from 'react-router-dom';
 

function Projects() {
    return (
        <>
            <div className='mb-5' id='proyectos'>
                <Container >
                    <div className='d-flex justify-content-center'>
                        <h2 className='aboutTitle mt-3 mb-4'>Nuestro trabajo</h2>
                    </div>
                    <Row className='projectContainer justify-content-center ms-4 me-4 mb-5'>
                        <Col lg={4} md={6} className='projectCol'>
                            <div className='projectCard'>
                                <img src={podcast} alt='sitio meeti' className='card-img-top cardImg' />
                                <Card.Body className='pCardBody'>
                                    <Card.Title className='projectTitle mt-2'>Landing page de Podcast</Card.Title>
                                    <Card.Text className='projectDesc mt-2'>
                                    Explora nuestro demo de ejemplo para una plataforma de podcast con una interfaz intuitiva para crear tu propio podcast.
                                    </Card.Text>
                                    <div>
                                        <Link className='btn pReadMore' to="podcast">Visitar sitio
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="white" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </Link>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={4} md={6} className='projectCol'>
                            <div className='projectCard'>
                                <img src={meeti} alt='sitio meeti' className='card-img-top cardImg' />
                                <Card.Body className='pCardBody'>
                                    <Card.Title className='projectTitle mt-2'>Landing page Meeti</Card.Title>
                                    <Card.Text className='projectDesc mt-2'>
                                    Descubre nuestro demo de diseño de una landing page para promocionar una app enfocada organizar y gestionar eventos.
                                    </Card.Text>
                                    <div>
                                        <Link className='btn pReadMore' to="meeti">Visitar sitio
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="white" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </Link>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={4} md={6} className='projectCol'>
                            <div className='projectCard'>
                                <img src={lideres} alt='sitio guitarras' className='card-img-top cardImg' />
                                <Card.Body className='pCardBody'>
                                    <Card.Title className='projectTitle mt-2'>Lideres de Altura</Card.Title>
                                    <Card.Text className='projectDesc mt-2'>
                                    Visita el sitio web que desarrollamos para una asociación dedicada a impulsar proyectos de valor social.
                                    </Card.Text>
                                    <div>
                                        <a href="https://www.lideresdealtura.com/portal/" className='btn pReadMore'>Visitar sitio
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="white" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </a>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>

    );
}

export default Projects;