import React from 'react';
//import style from './build/css/podcast.module.css';

import app from './build/img/app.jpg';
import comilla from './build/img/comilla.svg';
import grafica from './build/img/grafica.svg';
import header from './build/img/header.jpg';
import icono_inicio from './build/img/icono_inicio.svg';
import icono_negocio from './build/img/icono_negocio.svg';
import icono_pro from './build/img/icono_pro.svg';
import imagen_podcast from './build/img/imagen_podcast.svg';
import logo from './build/img/logo.svg';
import podcast_cover from './build/img/podcast_cover.png';
function PodcastApp() {
    return (
        <>
          

        </>

    );
}

export default PodcastApp;